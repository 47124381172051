body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.Reports table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

.Reports table tr:last-child td {
    border-bottom: none;
}

.Reports table th,
.Reports table td {
    margin: 0;
    padding: 0.5rem;
    border: 1px solid black;
}

.Reports table th:last-child,
.Reports table td:last-child {
    border-right: none;
}

.Fuels table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
}

.Fuels table tr:last-child td {
    border-bottom: none;
}

.Fuels table th,
.Fuels table td {
    margin: 0;
    padding: 0.5rem;
    border: 1px solid black;
}

.Fuels table th:last-child,
.Fuels table td:last-child {
    border-right: none;
}